import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { forwardRef } from 'react';
import { Tooltip } from 'src/components/mui-components';

type IconButtonRef = HTMLButtonElement & {
  title: string;
};

export const IconButton = forwardRef<IconButtonRef, MuiIconButtonProps>(
  ({ title, ...rest }, ref) => (
    <Tooltip title={rest.disabled ? undefined : title}>
      {/* Reason for div here: https://mui.com/material-ui/react-tooltip/#disabled-elements */}
      <div>
        <MuiIconButton ref={ref} {...rest} />
      </div>
    </Tooltip>
  ),
);
